
import React from 'react'
import { createUseStyles } from 'react-jss'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

export const query = graphql`
  query($slug: String!) {
    photosJson(slug: { eq: $slug }) {
      name
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }`

const DetailImg = (props: { data: { photosJson: { name: string, image: any } } }) => {
  const photo = props.data.photosJson


  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Image
        fluid={photo.image.childImageSharp.fluid}
        alt={photo.name}
        style={{ float: "left", padding: "1rem", width: "100%" }}
      />
    </div>
  )
}

export default DetailImg
